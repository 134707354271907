@import 'src/styles/theme.scss';
@import 'src/styles/mixins.scss';

.total-wrapper {
  &.full-height {
    height: 100vh;
    overflow: hidden;
  }
}

.main-page {
  text-align: center;
}

.page-wrapper {
  margin: 0 auto;
  padding-top: 118px;

  @include screen('tablet') {
    width: 100%;
    padding-left: 15px;
    padding-right: 15px;
  }

  @include screen('mobile') {
    width: 100%;
    padding-top: 97px;
  }
}

.fixedBg {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-size: contain;
  background-repeat: no-repeat;
  z-index: -1;
}

.header {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 11px 22px 11px 28px;
  border-bottom: 3px solid transparent;
  z-index: 1;
  transition: 0.3s;
  @include screen('mobile') {
    justify-content: flex-start;
    padding: 24px 17px 16px 20px;
    border-bottom: 2px solid transparent;
  }

  &.sticky {
    padding: 21px 24px 27px 23px;
    background-color: #09182f;
    border-color: #0c2b4b;

    @include screen('mobile') {
      background-color: #041023;
      border-color: #0c2b4b;
      padding: 27px 20px 18px 20px;
    }

    .title {
      opacity: 1;
      visibility: visible;
    }

    .n12-link {
      @include screen('mobile') {
        width: 36px;
      }
    }
  }

  .title {
    font-family: $yonit-medium;
    font-size: 54px;
    line-height: 0.95;
    color: #fff;
    opacity: 0;
    visibility: hidden;
    margin: 0;
    cursor: pointer;
    padding-right: 58px;
    transition: 0.3s;

    @include screen('mobile') {
      position: relative;
      font-size: 26px;
      padding-right: 21px;

      &::before {
        content: '';
        position: absolute;
        top: -1px;
        right: 10px;
        width: 1.5px;
        height: 21px;
        background-color: #fff;
      }
    }
  }

  .n12-link {
    width: 113px;
    padding-top: 12px;
    transition: 0.3s;
    @include screen('mobile') {
      width: 49px;
      padding-top: 0;
    }
    img {
      width: 100%;
    }
  }
}

.top-section {
  position: relative;
  max-width: 990px;
  margin: 0 auto;
  text-align: center;
  @include screen('mobile') {
    padding: 0 22px;
  }

  .title {
    display: inline-block;
    padding: 10px 11px 1px;
    font-family: $yonit-bold;
    font-size: 128px;
    line-height: 90px;
    color: #f0f0f0;
    margin: 0;
    background-color: #961010;

    @include screen('mobile') {
      padding: 5px 9px 0px;
      font-size: 60px;
      line-height: 44px;
    }

    // @include screen('mobile') {
    //   font-size: 84px;
    //   line-height: 0.77;
    // }
  }

  .subTitle {
    font-family: $yonit-regular;
    font-size: 47px;
    line-height: 58px;
    margin: 13px 0 24px;
    @include screen('mobile') {
      font-size: 21px;
      line-height: 21px;
      letter-spacing: 0.4px;
      margin: 13px 0 16px;
    }
  }

  .text {
    font-family: $open-sans;
    font-size: 24px;
    line-height: 33px;
    letter-spacing: -0.2px;
    font-weight: 400;
    margin: 0 0 25px;
    @include screen('mobile') {
      font-size: 18px;
      line-height: 24px;
      margin: 6px 0px 15px;
    }
  }

  .buttons {
    display: flex;
    justify-content: center;
    gap: 25px;

    @include screen('mobile') {
      flex-direction: column;
      align-items: center;
    }
  }

  .scroll-to-button {
    width: 389px;
    padding: 9px 0;
    font-family: $open-sans;
    font-size: 24px;
    line-height: 33px;
    font-weight: 700;
    background-color: #053550;
    transition: 0.3s;
    cursor: pointer;

    @include screen('mobile') {
      width: 263px;
      font-size: 18px;
      line-height: 24px;
    }

    &:hover {
      opacity: 0.8;
    }
  }
}

.search-container {
  display: flex;
  justify-content: space-between;
  position: relative;
  margin: 0 auto;
  width: 100%;
  max-width: 575px;
  padding: 65px 0 89px;
  @include screen('mobile') {
    padding: 35px 22px 54px;
  }

  .search-wrapper {
    display: flex;
    align-items: center;
    width: 575px;
    height: 51px;
    overflow: hidden;

    &.isFocused {
      input {
        border: solid 1px #8199a7;
        border-left-width: 0;
      }
    }

    @include screen('mobile') {
      flex-grow: 1;
    }
  }

  .input-wrapper {
    position: relative;
    flex-grow: 1;

    input {
      display: block;
      width: 100%;
      height: 49px;
      line-height: 40px;
      padding: 0 12px 0 31px;
      outline: none;
      font-size: 16px;
      font-family: $open-sans;
      font-weight: 400;
      color: #000;
      border: 1px solid #8199a7;
      border-left-width: 0;
      -webkit-appearance: none;
      border-top-right-radius: 5px;
      border-bottom-right-radius: 5px;
      border-top-left-radius: 0px;
      border-bottom-left-radius: 0px;
      background-color: #fff;

      &::placeholder {
        color: #7d8d96;
      }
    }
    input[type='search']::-ms-clear {
      display: none;
      width: 0;
      height: 0;
    }
    input[type='search']::-ms-reveal {
      display: none;
      width: 0;
      height: 0;
    }
    /* clears the ‘X’ from Chrome */
    input[type='search']::-webkit-search-decoration,
    input[type='search']::-webkit-search-cancel-button,
    input[type='search']::-webkit-search-results-button,
    input[type='search']::-webkit-search-results-decoration {
      display: none;
    }

    .clear-btn {
      position: absolute;
      width: 18px;
      height: 18px;
      left: 10px;
      top: 50%;
      transform: translate(0, -50%);
      cursor: pointer;
      &:before,
      &:after {
        content: '';
        position: absolute;
        width: 18px;
        height: 1px;
        top: 50%;
        left: 50%;
        background-color: #7d8d96;
      }

      &:before {
        transform: translate(-50%, -50%) rotate(45deg);
      }
      &:after {
        transform: translate(-50%, -50%) rotate(-45deg);
      }
    }
  }

  .search-btn {
    position: relative;
    width: 48px;
    height: 49px;
    flex-shrink: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #fff;
    border: 1px solid #8199a7;
    border-right-width: 0;
    border-top-left-radius: 5px;
    border-bottom-left-radius: 5px;
    cursor: pointer;
    transition: 0.3s;

    &:after {
      content: '';
      position: absolute;
      width: 1px;
      height: 31px;
      right: 0;
      top: 50%;
      transform: translate(0, -50%);
      background-color: #0c2b4b;
    }
    &:hover {
      border-color: transparent;
      background-color: #053550;
      .search-icon {
        path {
          fill: white;
        }
      }
    }
    &.disabled {
      cursor: inherit;
      pointer-events: none;
    }

    .search-icon {
      transition: 0.3s;
    }
  }

  .advanced-search-btn {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 48px;
    height: 51px;
    border: solid 1px transparent;
    background-color: rgba(255, 255, 255, 0.1);
    border-radius: 2px;
    cursor: pointer;

    @include screen('mobile') {
      flex-shrink: 0;
    }

    &:after {
      content: '';
      position: absolute;
      left: 11.5px;
      top: 14px;
      width: 6px;
      height: 6px;
      border-radius: 3px;
      background-color: rgba(222, 0, 0, 1);
      opacity: 0;
    }

    &.active {
      border: solid 1px rgba(222, 0, 0, 1);
      &:after {
        opacity: 1;
      }
    }

    .img {
      display: block;
      width: 18px;
    }
  }
}

.sorting {
  position: relative;
  padding-bottom: 25px;
  @include screen('mobile') {
    padding: 0px 13px 13px;
  }

  .sort-btn {
    font-family: 'Open Sans';
    font-size: 16px;
    font-weight: 700;
    padding: 12px 20px;
    border: 1px solid #fff;
    background-color: #2b2c2d;
    padding: 12px 11px 12px 13px;
    transition: 0.3s;

    &:hover {
      background-color: #de000033;
      border: 1px solid #fff;
    }

    &.active {
      background-color: #de0000;
      border: 1px solid #de0000;
    }

    &.AZSorting {
      border-right: 0;
      border-radius: 2px 0px 0px 2px;
    }
    &.reverseSorting {
      border-left: 0;
      border-radius: 0px 2px 2px 0px;
    }
  }
}

.full-width {
  border-top: 4px solid #fff;
  background-color: #08203a;
  scroll-margin-top: 102px; // header height
  padding-bottom: 25px;

  @include screen('mobile') {
    scroll-margin-top: 72px;
    padding-bottom: 18px;
  }

  &.is-search-result {
    border-top: 0;
    background-color: transparent;

    .grid-title {
      margin-top: 0;
    }
  }

  &.is-earlier-kidnepped {
    background-color: transparent;

    .grid-title {
      margin: 101px auto 33px;
      padding-right: 3px;

      @include screen('mobile') {
        max-width: 245px;
        margin: 33px auto 42px;
        padding-right: 0;
      }
    }

    .items-grid {
      padding-bottom: 75px;

      @include screen('mobile') {
        padding-bottom: 60px;
      }
    }
  }

  .grid-title {
    font-family: $yonit-bold;
    font-size: 50px;
    line-height: 55px;
    margin: 67px auto 39px;
    width: 77vw;

    @include screen('mobile') {
      width: 100%;
      font-size: 30px;
      line-height: 33px;
      text-align: center;
      margin: 56px auto 31px;
    }
  }

  .items-grid {
    padding-bottom: 46px;
  }
}

.items-grid {
  position: relative;
  width: 77vw;
  margin: 0 auto;
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-template-rows: min-content 1fr;
  grid-column-gap: 15px;
  grid-row-gap: 25px;
  padding-bottom: 130px;
  @include screen('mobile') {
    width: 100%;
    grid-template-columns: repeat(2, 1fr);
    row-gap: 16px;
    padding: 0 12px 102px;
  }
}

.btns {
  display: flex;
  align-items: center;
  justify-content: center;
  padding-top: 30px;
  padding-bottom: 30px;
  padding-left: 5px;
  border-top: 1px solid rgba(255, 255, 255, 0.1);
  border-bottom: 1px solid rgba(255, 255, 255, 0.1);

  .prev-btn,
  .next-btn {
    font-family: 'Open Sans';
    font-size: 20px;
    line-height: 20px;
    font-weight: 700;
    cursor: pointer;

    &.disabled {
      display: none;
    }
  }

  .prev-btn {
    position: relative;
  }

  .divider {
    width: 1px;
    height: 27px;
    margin: 0 20px;
    background-color: #d9d9d9;
    &.disabled {
      display: none;
    }
  }
}

.contact {
  font-family: 'Open Sans';
  font-size: 20px;
  line-height: 1;
  font-weight: 700;
  padding-top: 15px;
  padding-left: 5px;
  padding-bottom: 20px;
  text-align: center;
  @include screen('mobile') {
    font-size: 16px;
    line-height: 22px;
    text-align: right;
    padding: 14px 8px 20px;
  }
  &:hover {
    opacity: 0.8;
  }

  .whatsapp-link {
    display: flex;
    align-items: center;
    justify-content: center;
    @include screen('mobile') {
      max-width: 282px;
      margin: 0 auto;
    }

    img {
      width: 28px;
      height: 28px;
      margin-left: 10px;
      @include screen('mobile') {
        width: 24px;
        height: 24px;
      }
    }
  }
}

.no-search-results {
  position: relative;
  margin-bottom: 35px;
  padding: 32px 0;
  font-size: 40px;
  line-height: 20px;
  color: #fff;
  font-family: $yonit-bold;
  text-align: center;
}

.credits {
  color: #ffffffb3;
  font-family: $open-sans;
  text-align: center;
  padding-bottom: 30px;
  background-color: #08203A;

  @include screen('mobile') {
    text-align: center;
  }
  .rabbiLink {
    text-decoration: underline;
  }
}

.scroll-top-button {
  position: fixed;
  right: calc((11.5vw - 80px) / 2);
  bottom: 36px;
  width: 80px;
  height: 80px;
  padding: 0;

  &:hover {
    cursor: pointer;
  }

  @include screen('mobile') {
    right: 13px;
    bottom: 35px;
    width: 40px;
    height: 40px;
  }

  svg {
    height: inherit;
    width: inherit;
  }
}

@keyframes skeletonShine {
  to {
    background-position: 0 0;
  }
}
