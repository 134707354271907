@import 'src/styles/theme.scss';
@import 'src/styles/mixins.scss';

.grid-item {
  position: relative;
  width: 100%;
  padding: 15px 15px 62px 15px;
  border-radius: 2px;
  background-color: rgba(30, 58, 93, 0.15);
  transition: all ease 0.1s;
  @include screen('tablet') {
    padding: 10px 10px 62px 10px;
  }
  @include screen('mobile') {
    padding: 8px 8px 51px 8px;
  }

  &.is-earlier-kidnepped {
    .info {
      padding-top: 26px;

      @include screen('mobile'){
        padding-top: 9px;
      }

      .texts {
        @include screen('mobile'){
          padding-right: 0;
        }
      }

      .age-and-city {
        font-family: $yonit-medium;
        font-size: 20px;
        line-height: 25px;
        letter-spacing: 0.5px;
        padding-bottom: 5px;
  
        @include screen('mobile') {
          font-size: 16px;
          line-height: 1.5;
        }
      }
    }
  }

  &.animation {
    border: solid 1px transparent;
    background-position: -500px 0;
    background-image: linear-gradient(
      135deg,
      rgba(255, 255, 255, 0.1) 0%,
      rgba(255, 255, 255, 0.1) 20%,
      rgba(255, 255, 255, 0.2) 40%,
      rgba(255, 255, 255, 0.1) 100%
    );
    background-repeat: no-repeat;
    background-size: 1000px 100%;
    animation: skeletonShine 0.5s linear 0s infinite normal forwards;

    .photo {
      opacity: 0;
    }
    .info {
      opacity: 0;
    }
    .icon {
      opacity: 0;
    }
    .button {
      opacity: 0;
    }
  }

  .photo {
    width: 100%;
    height: auto;
    aspect-ratio: 1 / 1;
    border-radius: 2px;
    overflow: hidden;
    transition: all ease 0.1s;

    @include screen('mobile') {
      width: calc(100% - 4px);
      margin: 0 auto;
    }

    img {
      display: block;
      width: 100%;
      height: 100%;
      object-fit: cover;
      object-position: 50% 10%;
    }
  }

  .infoWrap {
    position: relative;
  }

  .top {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  .info {
    position: relative;
    padding-top: 13px;
    padding-bottom: 17px;
    flex-grow: 1;
    transition: all ease 0.1s;
    @include screen('mobile') {
      padding-bottom: 0;
    }

    .texts {
      @include screen('mobile') {
        padding-left: 0;
        padding-right: 6px;
      }
    }

    .name {
      font-family: $yonit-bold;
      font-size: 30px;
      line-height: 35px;
      letter-spacing: 0.5px;
      text-align: right;

      @include screen('tablet') {
        font-size: 2vw;
      }

      @include screen('mobile') {
        font-size: 20px;
        line-height: 22px;
      }
    }

    .description {
      font-family: $open-sans;
      font-weight: 400;
      font-size: 16px;
      line-height: 20px;
      color: rgba(255, 255, 255, 0.7);
      overflow: hidden;
      max-height: 60px;
      display: -webkit-box;
      -webkit-line-clamp: 3;
      -webkit-box-orient: vertical;

      @include screen('mobile') {
        font-size: 15px;
        line-height: 17px;
        max-height: 51px;
      }
    }
  }

  .button {
    position: absolute;
    left: 15px;
    right: 15px;
    bottom: 15px;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 11px 0;
    font-family: $yonit-bold;
    font-size: 22px;
    line-height: 22px;
    border: none;
    background-color: #961010;
    border-radius: 2px;
    transition: 0.3s;
    @include screen('mobile') {
      font-size: 16px;
      left: 9px;
      right: 10px;
      bottom: 8px;
      padding: 6px 0 7px;
    }

    &:hover {
      background-color: #5c0b0b;
    }
  }
}

@keyframes skeletonShine {
  to {
    background-position: 0 0;
  }
}
