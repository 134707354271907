// Colors
// -------------------------------------------------------------------------------------------------

// Typography
// -------------------------------------------------------------------------------------------------

$almoni-light: 'almoni-light', sans-serif;
$almoni-regular: 'almoni-regular', sans-serif;
$almoni-demibold: 'almoni-demibold', sans-serif;
$almoni-bold: 'almoni-bold', sans-serif;
$yonit-light: 'Yonit-Light', sans-serif;
$yonit-regular: 'Yonit-Regular', sans-serif;
$yonit-medium: 'Yonit-Medium', sans-serif;
$yonit-bold: 'Yonit-Bold', sans-serif;
$open-sans: 'Open Sans', sans-serif;

// Media queries
// -------------------------------------------------------------------------------------------------
$media-mobile-small-max: 350;
$media-mobile-medium-max: 540;
$media-mobile-max: 767;
$media-tablet-min: 768;
$media-tablet-max: 1024;
$media-desktop-min: $media-tablet-max + 1;
$media-desktop-hd-min: 1920;
$media-desktop-small-max: 1300;

$media-mobile: '(max-width: #{$media-mobile-max}px)';
$media-mobile-medium: '(max-width: #{$media-mobile-medium-max}px)';
$media-mobile-small: '(max-width: #{$media-mobile-small-max}px)';
$media-mobile-landscape: '(max-width: #{$media-mobile-max}px) and (orientation: landscape)';
$media-mobile-portrait: '(max-width: #{$media-mobile-max}px) and (orientation: portrait)';

$media-tablet: '(min-width: #{$media-tablet-min}px) and (max-width: #{$media-tablet-max}px)';
$media-tablet-landscape: '(min-width: #{$media-tablet-min}px) and (max-width: #{$media-tablet-max}px) and (orientation: landscape)';
$media-tablet-portrait: '(min-width: #{$media-tablet-min}px) and (max-width: #{$media-tablet-max}px) and (orientation: portrait)';

$media-desktop: '(min-width: #{$media-desktop-min}px)';
$media-desktop-hd: '(min-width: #{$media-desktop-hd-min}px)';
$media-small-desktop: '(min-width: #{$media-desktop-min}px) and (max-width: #{$media-desktop-small-max}px)';

$media-non-mobile: '(min-width: #{$media-mobile-max}px)';
$media-non-desktop: '(max-width: #{$media-tablet-max}px)';
