@import url("https://fonts.googleapis.com/css2?family=Open+Sans:wght@300;400;500;600;700;800&display=swap");
@font-face {
  font-family: "Yonit-Light";
  src: url("./fonts/Yonit-Light.ttf");
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: "Yonit-Regular";
  src: url("./fonts/Yonit-Regular.ttf");
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: "Yonit-Medium";
  src: url("./fonts/Yonit-Medium.ttf");
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: "Yonit-Bold";
  src: url("./fonts/Yonit-Bold.ttf");
  font-weight: normal;
  font-style: normal;
}
html {
  font-size: 62.5%;
  scroll-behavior: auto;
  -webkit-text-size-adjust: 100%;
  -ms-text-size-adjust: 100%;
  -webkit-font-variant-ligatures: none;
  font-variant-ligatures: none;
  text-rendering: optimizeLegibility;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  font-smoothing: antialiased;
  text-shadow: rgba(0, 0, 0, 0.01) 0 0 1px;
  -ms-overflow-style: scrollbar;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

body {
  padding: 0;
  margin: 0;
  font-family: "almoni-bold", sans-serif;
  font-size: 12px;
}
@media only screen and (max-width: 767px) {
  body {
    min-height: inherit;
  }
}
body[class^=no-scroll-] {
  overflow: hidden;
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
textarea:-webkit-autofill,
textarea:-webkit-autofill:hover,
textarea:-webkit-autofill:focus,
select:-webkit-autofill,
select:-webkit-autofill:hover,
select:-webkit-autofill:focus {
  border: none;
  -webkit-text-fill-color: var(--ria-input-color);
  -webkit-box-shadow: none;
  transition: background-color 5000s ease-in-out 0s;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type=number] {
  -moz-appearance: textfield;
}

a {
  color: inherit;
  text-decoration: none;
}

button {
  padding: 0;
  border: 0;
  outline: none;
  background: none;
  box-shadow: none;
  cursor: pointer;
  text-align: center;
  color: inherit;
}