@import 'src/styles/theme.scss';
@import 'src/styles/mixins.scss';

footer {
  padding: 4px 0 43px;
  background-color: #08203A;
  @include screen('mobile') {
    padding: 35px 0 30px;
  }
  .footer-wrapper {
    width: 100%;
    max-width: 1345px;
    margin: 0 auto;
    padding: 0 10px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    @include screen('mobile') {
      padding: 0 17px;
    }

    .text {
      font-size: 21px;
      line-height: 28px;
      color: #fff;
      font-family: $open-sans;
      font-weight: 400;
      text-align: center;
      @include screen('mobile') {
        font-size: 20px;
        line-height: 34px;
      }
    }

    .logo-wrapper {
      width: 162px;
      padding-top: 39px;
      @include screen('mobile') {
        width: 110px;
        padding-top: 0;
      }
      img {
        display: block;
        width: 100%;
      }
    }
  }
}
