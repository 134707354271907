@import 'src/styles/theme.scss';
@import 'src/styles/mixins.scss';

.person-modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  z-index: 100;
  display: flex;
  align-items: center;
  justify-content: center;
  opacity: 1;
  visibility: visible;
  pointer-events: auto;
  transition: opacity 0.2s ease, visibility 0.2s ease;

  &.hidden {
    opacity: 0;
    visibility: hidden;
    pointer-events: none;
  }

  .bg {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    background-color: rgba(0, 22, 42, 0.9);
    opacity: 0.9;
    z-index: 1;
  }

  .modal-content {
    width: 663px;
    min-height: 300px;
    position: relative;
    z-index: 2;
    background-color: rgba(12, 43, 75, 1);
    border-radius: 6px;
    padding: 42px 30px 42px;
    display: flex;
    flex-flow: column nowrap;
    align-items: center;

    @include screen('mobile') {
      position: relative;
      width: 78%;
      padding: 42px 11px 42px;
    }

    .photo {
      width: 194px;
      height: 194px;
      border-radius: 3px;
      margin-bottom: 16px;
      overflow: hidden;

      img {
        display: block;
        width: 100%;
        height: 100%;
        object-fit: cover;
        object-position: 0 25%;
      }
    }

    .title {
      font-family: $yonit-bold;
      color: #fff;
      font-size: 32px;
      line-height: 25px;
      letter-spacing: 0.5px;
      text-align: center;
      margin-bottom: 2px;
      @include screen('mobile') {
        font-size: 24px;
        line-height: 25px;
        letter-spacing: 0.5px;
        margin-bottom: 1px;
      }
    }

    .additional-info {
      padding-bottom: 10px;
      font-family: $yonit-regular;
      color: #fff;
      font-size: 26px;
      line-height: 28px;
      letter-spacing: 0.5px;
      text-align: center;
      @include screen('mobile') {
        padding-bottom: 4px;
        font-size: 20px;
        line-height: 22px;
        letter-spacing: 0.5px;
      }
    }

    .subtitle {
      font-family: 'Open Sans', sans-serif;
      color: #fff;
      opacity: 0.9;
      font-size: 16px;
      line-height: 21px;
      text-align: right;
      @include screen('mobile') {
        font-size: 16px;
        line-height: 22px;
        height: auto;
        max-height: 252px;
        overflow: auto;
      }
    }

    .close-btn {
      position: absolute;
      top: 9px;
      right: 9px;
      width: 18px;
      height: 18px;
      display: block;

      &:hover {
        span {
          opacity: 0.8;
        }
      }

      span {
        position: absolute;
        width: 20px;
        height: 2px;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%) rotate(45deg);
        background-color: white;
        transition: all ease 0.3s;

        &:first-child {
          transform: translate(-50%, -50%) rotate(-45deg);
        }
      }
    }

    .video {
      width: 412px;
      height: 232px;
      margin-bottom: 20px;
      overflow: hidden;

      @include screen('mobile') {
        width: 100%;
        max-width: 272px;
        height: 161px;
        margin-bottom: 16px;
      }

      iframe {
        width: 100%;
        height: 100%;
        overflow: hidden !important;
      }
    }

    .link-button {
      width: 262px;
      padding: 5px 0;
      margin-top: 37px;
      font-size: 22px;
      line-height: 22px;
      font-family: $yonit-bold;
      color: #fff;
      text-align: center;
      background-color: rgba(0, 22, 42, 1);
      border-bottom: 1px solid rgba(228, 35, 35, 1);
      border-radius: 2px;
      transition: all ease 0.3s;
      @include screen('mobile') {
        margin-top: 32px;
      }
      &:hover {
        border-bottom: 1px solid transparent;
      }
    }
  }
}
